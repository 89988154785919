(function($) {

	function setCircles() {
		$('#galeria .circle').height($('#galeria .circle').width());
	}


	// Load Events
	$(document).ready(function() {
	  

		$("#hero-banner").owlCarousel({
			loop: true,
			nav: false,
			dots: true,
			autoplay : true,
			dotsEach : false,
			margin : 0,
			items : 1
		});
	  
		
		$('.lightGallery').lightGallery({
			selector: 'a',
			download:false,
			counter:false,
			autoplay:false,
			zoom:false
		});
/*
		
		var hashTagActive = "";
	    $('a[href*=#]:not([href=#])').click(function (event) {
	        if(hashTagActive != this.hash) { //this will prevent if the user click several times the same link to freeze the scroll.
	            event.preventDefault();
	            //calculate destination place
	            var dest = 0;
	            if ($(this.hash).offset().top > $(document).height() - $(window).height()) {
	                dest = $(document).height() - $(window).height();
	            } else {
	                dest = $(this.hash).offset().top;
	            }
	            //go to destination
	            $('html,body').animate({
	                scrollTop: dest
	            }, 2000, 'swing');
	            hashTagActive = this.hash;
	        }
	    });  
*/

		$('#etlap_modal').modal({
			show:false
		});

		$('a[href="#etlap"]').click(function() {
			$('#etlap_modal').modal('show');
		});
	
		
		setCircles();
	  
	});
	
	$(window).resize(function() {
		setCircles();
	});

})(jQuery); 
